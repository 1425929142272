import { ListingTypes } from '../util/types';

/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - listingTypeConfig (optional):  Relationship configuration against listing types.
 *   - limitToListingTypeIds:         Indicator whether this listing field is relevant to a limited set of listing types.
 *   - listingTypeIds:                An array of listing types, for which this custom listing field is
 *                                    relevant and should be added. This is mandatory if limitToListingTypeIds is true.
 * - categoryConfig (optional):     Relationship configuration against categories.
 *   - limitToCategoryIds:            Indicator whether this listing field is relevant to a limited set of categories.
 *   - categoryIds:                   An array of categories, for which this custom listing field is
 *                                    relevant and should be added. This is mandatory if limitToCategoryIds is true.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
// export const listingFields = [
// {
//   "scope": "public",
//   "label": "Gears",
//   "key": "gears",
//   "schemaType": "long",
//   "numberConfig": {
//     "minimum": 1,
//     "maximum": 24
//   },
//   "filterConfig": {
//     "indexForSearch": true,
//     "group": "primary",
//     "label": "Gears"
//   }
// }
// {
//   key: 'bikeType',
//   scope: 'public',
//   schemaType: 'enum',
//   enumOptions: [
//     { option: 'city-bikes', label: 'City bikes' },
//     { option: 'electric-bikes', label: 'Electric bikes' },
//     { option: 'mountain-bikes', label: 'Mountain bikes' },
//     { option: 'childrens-bikes', label: "Children's bikes" },
//   ],
//   categoryConfig: {
//     limitToCategoryIds: true,
//     categoryIds: ['cats'],
//   },
//   filterConfig: {
//     indexForSearch: true,
//     filterType: 'SelectMultipleFilter', //'SelectSingleFilter',
//     label: 'Bike type',
//     group: 'primary',
//   },
//   showConfig: {
//     label: 'Bike type',
//     isDetail: true,
//   },
//   saveConfig: {
//     label: 'Bike type',
//     placeholderMessage: 'Select an option…',
//     isRequired: true,
//     requiredMessage: 'You need to select a bike type.',
//   },
// },
// {
//   key: 'tire',
//   scope: 'public',
//   schemaType: 'enum',
//   enumOptions: [
//     { option: '29', label: '29' },
//     { option: '28', label: '28' },
//     { option: '27', label: '27' },
//     { option: '26', label: '26' },
//     { option: '24', label: '24' },
//     { option: '20', label: '20' },
//     { option: '18', label: '18' },
//   ],
//   filterConfig: {
//     indexForSearch: true,
//     label: 'Tire size',
//     group: 'secondary',
//   },
//   showConfig: {
//     label: 'Tire size',
//     isDetail: true,
//   },
//   saveConfig: {
//     label: 'Tire size',
//     placeholderMessage: 'Select an option…',
//     isRequired: true,
//     requiredMessage: 'You need to select a tire size.',
//   },
// },
// {
//   key: 'brand',
//   scope: 'public',
//   schemaType: 'enum',
//   enumOptions: [
//     { option: 'cube', label: 'Cube' },
//     { option: 'diamant', label: 'Diamant' },
//     { option: 'ghost', label: 'GHOST' },
//     { option: 'giant', label: 'Giant' },
//     { option: 'kalkhoff', label: 'Kalkhoff' },
//     { option: 'kona', label: 'Kona' },
//     { option: 'otler', label: 'Otler' },
//     { option: 'vermont', label: 'Vermont' },
//   ],
//   filterConfig: {
//     indexForSearch: true,
//     label: 'Brand',
//     group: 'secondary',
//   },
//   showConfig: {
//     label: 'Brand',
//     isDetail: true,
//   },
//   saveConfig: {
//     label: 'Brand',
//     placeholderMessage: 'Select an option…',
//     isRequired: true,
//     requiredMessage: 'You need to select a brand.',
//   },
// },
// {
//   key: 'accessories',
//   scope: 'public',
//   schemaType: 'multi-enum',
//   enumOptions: [
//     { option: 'bell', label: 'Bell' },
//     { option: 'lights', label: 'Lights' },
//     { option: 'lock', label: 'Lock' },
//     { option: 'mudguard', label: 'Mudguard' },
//   ],
//   filterConfig: {
//     indexForSearch: true,
//     label: 'Accessories',
//     searchMode: 'has_all',
//     group: 'secondary',
//   },
//   showConfig: {
//     label: 'Accessories',
//   },
//   saveConfig: {
//     label: 'Accessories',
//     placeholderMessage: 'Select an option…',
//     isRequired: false,
//   },
// },
// // An example of how to use transaction type specific custom fields and private data.
// {
//   key: 'note',
//   scope: 'public',
//   schemaType: 'text',
//   listingTypeConfig: {
//     limitToListingTypeIds: true,
//     listingTypeIds: ['product-selling'],
//   },
//   showConfig: {
//     label: 'Extra notes',
//   },
//   saveConfig: {
//     label: 'Extra notes',
//     placeholderMessage: 'Some public extra note about this bike...',
//   },
// },
// {
//   key: 'privatenote',
//   scope: 'private',
//   schemaType: 'text',
//   listingTypeConfig: {
//     limitToListingTypeIds: true,
//     listingTypeIds: ['daily-booking'],
//   },
//   saveConfig: {
//     label: 'Private notes',
//     placeholderMessage: 'Some private note about this bike...',
//   },
// },
// ];

export const ConfigFieldInputKey = Object.freeze({
  BUSINESS_CATEGORY: 'business_category',
  BUSINESS_REG_TYPE: 'busines_registeration_type',
  BUSINESS_LOGO: 'business_logo',
  BUSINESS_PHONE_NUMBER: 'business_phone_number',
  BUSINESS_EMAIL: 'business_email',
  BUSINESS_IS_DELIVERY_SERVICE_REQUIRED: 'business_is_delivery_service_required',
  BUSINESS_IS_ACCEPT_CASH_ON_DELIVERY: 'business_is_accept_cash_on_delivery',
  BUSINESS_IS_HOME_SERVICE_AVAILABLE: 'business_is_home_service_available',
  BUSINESS_IS_STORE_PICKUP_AVAILABLE: 'business_is_store_pickup_available',
  BUSINESS_DELIVERY_INSTRUCTIONS: 'business_delivery_instructions',
  BUSINESS_COUNTRY: 'business_country',
  BUSINESS_ADDRESS: 'business_address',
  BUSINESS_CITY: 'business_city',
  BUSINESS_STATE: 'business_state',
  DEAL_START_DATE: 'deal_start_date',
  DEAL_END_DATE: 'deal_end_date',
  DEAL_START_TIME: 'deal_start_time',
  DEAL_END_TIME: 'deal_end_time',
  CONDITION_FOR_RECEIVING_GIFT: 'condition_for_receiving_gift',
  FREE_GIFT_CONDITION_VALUE: 'free_gift_condition_value',
  SERVICE_DURATION: 'serviceDuration',
  MINS_BETWEEN_NEXT_BOOKING: 'mins_between_next_booking',
});

export const listingFields = [
  {
    key: 'business_logo',
    scope: 'public',
    schemaType: 'single_image_upload',
    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.BUSINESS_PROFILE],
    },
    showConfig: {
      label: 'Business logo',
    },
    saveConfig: {
      label: 'Business logo',
      isRequired: true,
    },
  },
  {
    key: 'business_phone_number',
    scope: 'public',
    schemaType: 'phone_number',
    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.BUSINESS_PROFILE],
    },
    showConfig: {
      label: 'Business Phone Number',
    },
    saveConfig: {
      label: 'Business Phone Number',
      isRequired: true,
    },
  },
  {
    key: 'business_email',
    scope: 'public',
    schemaType: 'email',
    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.BUSINESS_PROFILE],
    },
    showConfig: {
      label: 'Business Email',
    },
    saveConfig: {
      label: 'Business Email',
      isRequired: true,
    },
  },

  //businessCategory
  {
    key: ConfigFieldInputKey.BUSINESS_CATEGORY,
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'individual', label: 'Individual' },
      { option: 'group', label: 'Group' },
      { option: 'company', label: 'Company' },
      { option: 'NGO', label: 'NGO' },
    ],

    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.BUSINESS_PROFILE],
    },
    showConfig: {
      label: '',
      isDetail: true,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Business Category',
      isRequired: true,
      requiredMessage: 'You need to select a business category.',
    },
  },
  {
    scope: 'public',
    key: ConfigFieldInputKey.BUSINESS_IS_DELIVERY_SERVICE_REQUIRED,
    schemaType: 'boolean',
    label: 'Is delivery service required ?',
    saveConfig: {
      placeholderMessage: 'Business name',
      isRequired: true,
      requiredMessage: 'You need to add a name.',
      label: 'Is delivery service required ?',
    },
    showConfig: {
      label: 'Is delivery service required',
    },
    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.BUSINESS_PROFILE],
    },
  },
  {
    scope: 'public',
    key: ConfigFieldInputKey.BUSINESS_IS_ACCEPT_CASH_ON_DELIVERY,
    schemaType: 'boolean',
    label: 'Accept cash on delivery ?',
    saveConfig: {
      placeholderMessage: 'Business name',
      isRequired: true,
      requiredMessage: 'You need to add a name.',
      label: 'Accept cash on delivery ?',
    },
    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.BUSINESS_PROFILE],
    },
  },
  {
    key: ConfigFieldInputKey.BUSINESS_DELIVERY_INSTRUCTIONS,
    scope: 'public',
    schemaType: 'text',
    label: 'Delivery Instructions',
    numberConfig: {
      minimum: 1,
      maximum: 200,
    },
    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.BUSINESS_PROFILE],
    },
    saveConfig: {
      placeholderMessage: 'Add Delivery Instructions',
      isRequired: false,
      requiredMessage: 'You need to add a name.',
      label: 'Accept cash on delivery ?',
    },
  },
  {
    scope: 'public',
    key: ConfigFieldInputKey.BUSINESS_IS_HOME_SERVICE_AVAILABLE,
    schemaType: 'boolean',
    label: 'Is home service available ?',
    saveConfig: {
      placeholderMessage: 'Delivery Instructions',
      isRequired: true,
      label: 'Is home service available ?',
    },

    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.BUSINESS_PROFILE],
    },
  },
  {
    scope: 'public',
    key: ConfigFieldInputKey.BUSINESS_IS_STORE_PICKUP_AVAILABLE,
    schemaType: 'boolean',
    label: 'Is home service available ?',
    saveConfig: {
      placeholderMessage: 'Delivery Instructions',
      isRequired: true,
      label: 'Is store pickup available ?',
    },

    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.BUSINESS_PROFILE],
    },
  },
  {
    key: ConfigFieldInputKey.BUSINESS_REG_TYPE,
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'private', label: 'Private' },
      { option: 'commercial', label: 'Commercial' },
      { option: 'group', label: 'Group' },
      { option: 'license', label: 'License' },
    ],
    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.BUSINESS_PROFILE],
    },

    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter', //'SelectSingleFilter',
      label: '',
      group: 'primary',
    },
    showConfig: {
      label: '',
      isDetail: true,
    },
    saveConfig: {
      label: '',
      placeholderMessage: 'Select Registeration Type',
      isRequired: true,
      requiredMessage: 'You need to select a business registeration type.',
    },
  },
  //Next Visit Discount Configs

  {
    scope: 'public',
    key: 'start_time_for_the_next_visit',
    schemaType: 'date_time',
    label: 'Start Time For The Next Visit',

    filterConfig: {
      indexForSearch: true,
      group: 'primary',
      label: 'Start Time For The Next Visit',
    },
    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.NEXT_VISIT_DISCOUNT],
    },
    saveConfig: {
      placeholderMessage: 'Start Time For The Next Visit',
      isRequired: true,
      requiredMessage: 'You need to add start time for the next visit.',
      label: 'Start Time For The Next Visit',
    },
  },
  {
    scope: 'public',
    key: 'end_time_for_the_next_visit',
    schemaType: 'date_time',
    label: 'End Time For The Next Visit',

    filterConfig: {
      indexForSearch: true,
      group: 'primary',
      label: 'End Time For The Next Visit',
    },
    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.NEXT_VISIT_DISCOUNT],
    },
    saveConfig: {
      placeholderMessage: 'End Time For The Next Visit',
      isRequired: true,
      requiredMessage: 'You need to add end time for the next visit.',
      label: 'End Time For The Next Visit',
    },
  },

  //Flash Sale Configs
  {
    scope: 'public',
    key: 'promo_code',
    schemaType: 'single_line_text',
    label: 'Unique Promo Code',

    filterConfig: {
      indexForSearch: true,
      group: 'primary',
      label: 'Unique Promo Code',
    },
    listingTypeConfig: {
      limitToListingTypeIds: true,
      listingTypeIds: [ListingTypes.PROMO_CODE],
    },
    saveConfig: {
      placeholderMessage: 'Unique Promo Code',
      isRequired: true,
      requiredMessage: 'You need to add a name.',
      label: 'Unique Promo Code',
    },
  },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
    defaultListingFields: {
      location: true,
      payoutDetails: true,
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  //   defaultListingFields: {
  //     shipping: true,
  //     pickup: true,
  //     payoutDetails: true,
  //   },
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //     location: true,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
export const DealType = Object.freeze({
  AMOUNT_DISCOUNT: 'amountDiscount',
  PERCENTAGE_DISCOUNT: 'percentageDiscount',
  BULK_DISCOUNT: 'bulkDiscount',
  PRODUCT_BUNDLES: 'productBundles',
  SERVICE_BUNDLES: 'serviceBundles',
  FLASH_SALES: 'Flash Sales',
  LIMITED_TIME_OFFERS: 'Limited-Time Offers',
  REFERRAL_DISCOUNTS: 'Referral Discounts',
  FRIEND_DISCOUNTS: 'Friend Discounts',
  GROUP_DISCOUNTS: 'Group Discounts',
  VIRAL_DISCOUNTS: 'Viral Discounts',
  CASHBACK_DISCOUNTS: 'Cashback Discounts',
  PROMO_CODES: 'Promo Codes',
  NEXT_VISIT_DISCOUNT: 'Next Visit Discount',
  BUY_ONE_GET_ONE_FREE: 'buyOneGetOneFree',
  BUY_ONE_GET_ONE_HALF_OFF: 'buyOneGetOneHalfOff',
  FREE_GIFTS_WITH_PURCHASE: 'freeGiftsWithPurchase',
  FREE_SAMPLES: 'freeSamples',
  FREE_DELIVERIES: 'Free Deliveries',
});
