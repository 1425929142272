import pick from 'lodash/pick';
import { initiatePrivileged, transitionPrivileged } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { addBuffer } from '../../util/dates';
import { transitions } from '../../transactions/transactionProcessBooking';
import { types as sdkTypes } from '../../util/sdkLoader';
import { transitions as bookingTransition } from '../../transactions/transactionProcessBooking';
import { transitions as purchaseTransition } from '../../transactions/transactionProcessPurchase';
const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/CheckoutPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/CheckoutPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/CheckoutPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const INITIATE_INQUIRY_REQUEST = 'app/CheckoutPage/INITIATE_INQUIRY_REQUEST';
export const INITIATE_INQUIRY_SUCCESS = 'app/CheckoutPage/INITIATE_INQUIRY_SUCCESS';
export const INITIATE_INQUIRY_ERROR = 'app/CheckoutPage/INITIATE_INQUIRY_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  isClockInSync: false,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  initiateInquiryInProgress: false,
  initiateInquiryError: null,
  cartListings: null,
  cartAuthorId: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS: {
      // Check that the local devices clock is within a minute from the server
      const lastTransitionedAt = payload.transaction?.attributes?.lastTransitionedAt;
      const localTime = new Date();
      const minute = 60000;
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
        isClockInSync: Math.abs(lastTransitionedAt?.getTime() - localTime.getTime()) < minute,
      };
    }
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case INITIATE_INQUIRY_REQUEST:
      return { ...state, initiateInquiryInProgress: true, initiateInquiryError: null };
    case INITIATE_INQUIRY_SUCCESS:
      return { ...state, initiateInquiryInProgress: false };
    case INITIATE_INQUIRY_ERROR:
      return { ...state, initiateInquiryInProgress: false, initiateInquiryError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const initiateInquiryRequest = () => ({ type: INITIATE_INQUIRY_REQUEST });
export const initiateInquirySuccess = () => ({ type: INITIATE_INQUIRY_SUCCESS });
export const initiateInquiryError = e => ({
  type: INITIATE_INQUIRY_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

/**
 * INITIATE ORDER
 */
export const initiateOrder = ({
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition,
}) => (dispatch, getState, sdk) => {
  try {
    // If we already have a transaction ID, we should transition, not
    // initiate.
    const isTransition = !!transactionId;

    const { deliveryMethod, quantity, cart, bookingDates, ...otherOrderParams } = orderParams;

    const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};

    const bookingParamsMaybe = bookingDates || {};

    // if (bookingDates) {
    //   bookingParamsMaybe = {
    //     ...bookingDates,
    //     bookingEnd: addBuffer(bookingDates?.bookingEnd),
    //     bookingDisplayEnd: bookingDates?.bookingEnd,
    //     bookingDisplayStart: bookingDates?.bookingStart,
    //   };
    // }

    // Parameters only for client app's server
    const orderData = deliveryMethod ? { cart, deliveryMethod } : { cart };

    // Parameters for Marketplace API
    const transitionParams = {
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...otherOrderParams,
    };

    const bodyParams = isTransition
      ? {
          id: transactionId,
          transition: transitionName,
          params: transitionParams,
        }
      : {
          processAlias,
          transition: transitionName,
          params: transitionParams,
        };
    const queryParams = {
      include: ['booking', 'provider', 'listing'],
      expand: true,
    };

    const handleSucces = response => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(initiateOrderSuccess(order));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return order;
    };

    const handleError = e => {
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
        listingId: orderParams.listingId.uuid,
        ...quantityMaybe,
        ...bookingParamsMaybe,
        ...orderData,
      });
      throw e;
    };
    if (isTransition && isPrivilegedTransition) {
      // transition privileged
      return transitionPrivileged({
        isSpeculative: false,
        orderData,
        bodyParams,
        queryParams,
      })
        .then(handleSucces)
        .catch(handleError);
    } else if (isTransition) {
      // transition non-privileged
      return sdk.transactions
        .transition(bodyParams, queryParams)
        .then(handleSucces)
        .catch(handleError);
    } else if (isPrivilegedTransition) {
      // initiate privileged
      return initiatePrivileged({
        isSpeculative: false,
        orderData,
        bodyParams,
        queryParams,
      })
        .then(handleSucces)
        .catch(handleError);
    } else {
      // initiate non-privileged
      return sdk.transactions
        .initiate(bodyParams, queryParams)
        .then(handleSucces)
        .catch(handleError);
    }
  } catch (error) {}
};

export const confirmPayment = params => (dispatch, getState, sdk) => {
  const { transactionId, transitionName, transitionParams = {} } = params;
  dispatch(confirmPaymentRequest());
  const bodyParams = {
    id: transactionId,
    transition: transitionName,
    params: transitionParams,
  };
  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  return sdk.transactions
    .transition(bodyParams, queryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(confirmPaymentSuccess(order?.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = transactionId ? { transactionId: transactionId?.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

/**
 * creating child transactions for both service/product
 */
const createChildTransactions = async (orderParams, sdk, isBookingProcess) => {
  //remove parent listing from cart
  const { attributes, id, listing } = orderParams;
  const parentTransactionId = id.uuid;
  const { cart } = attributes?.protectedData;
  cart?.deliveryMethod ? delete cart?.deliveryMethod : null;
  if (isBookingProcess) {
    for (const [key, value] of Object.entries(cart)) {
      if (value?.staffId === listing?.id?.uuid) {
        delete cart[key];
        break;
      }
    }
  } else {
    delete cart[listing?.id?.uuid];
  }
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };
  const childTransaction = Object.keys(cart).map(item => {
    const quantity = cart[item].count;
    const isService = cart[item].type === 'service';
    //to be discuss, how to get dynamic process alias for different listings
    const processAlias = isService ? 'default-booking/release-1' : 'default-purchase/release-1';
    const transitionName = transitions.CHILD_REQUEST_PAYMENT;
    const bookingParams = {
      listingId: cart[item]?.staffId,
      bookingStart: new Date(Number(cart[item]?.bookingStart)),
      bookingEnd: new Date(Number(cart[item]?.bookingEnd)),
      protectedData: {
        serviceId: item,
        unitType: 'hour',
        deliveryMethod: 'pickup',
        parentTransactionId: parentTransactionId,
      },
    };
    const purchaseParams = {
      listingId: item,
      stockReservationQuantity: quantity,
      protectedData: {
        parentTransactionId: parentTransactionId,
      },
    };
    const bodyParams = {
      processAlias: processAlias,
      transition: transitionName,
      params: {
        ...(isService ? bookingParams : purchaseParams),
      },
    };

    const handleSuccess = response => {
      return response.data.data.id.uuid;
    };
    const handleError = error => {
      console.log('child initiate error', JSON.stringify(error));
    };
    return initiatePrivileged({
      isSpeculative: false,
      bodyParams,
      queryParams,
    })
      .then(handleSuccess)
      .catch(handleError);
  });

  return Promise.all(childTransaction).then(childIds => {
    // Update child transaction ids
    const parentBodyParams = {
      id: parentTransactionId,
      transition: isBookingProcess
        ? bookingTransition.CONFIRM_PAYMENT
        : purchaseTransition.UPDATE_CHILD_TRANSCTIONS,
      params: {
        protectedData: {
          childTransactions: childIds,
        },
      },
    };
    return sdk.transactions
      .transition(parentBodyParams, {
        expand: true,
      })
      .then(res => {
        const entities = denormalisedResponseEntities(res);
        const order = entities[0];
        return order;
      });
  });
};

/**
 * intiate child and update parent.
 * in this step we can have both products and service
 * initate child service/products. if parent is product then update the product instead of creating
 */
export const initateChildUpdateParentTransaction = params => async (dispatch, getState, sdk) => {
  try {
    const { orderParams, isBookingProcess } = params;
    const response = await createChildTransactions(orderParams, sdk, isBookingProcess);
    return response;
  } catch (error) {}
};

/**
 * initiate child transactions
 */
export const initateChildTransactions = ({
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  // isPrivilegedTransition,
}) => (dispatch, getState, sdk) => {
  try {
    const { attributes, id, relationships } = orderParams;
    const parentTransactionId = id.uuid;
    const { cart, deliveryMethod } = attributes?.protectedData;
    const { listing } = relationships || {};

    //remove parent id
    delete cart[listing?.data?.id?.uuid];
    cart?.deliveryMethod ? delete cart?.deliveryMethod : null;

    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };

    const chidTransaction = Object.keys(cart).map(item => {
      const count = cart[item].count;

      const bodyParams = {
        processAlias: processAlias,
        transition: transitionName,
        params: {
          listingId: item,
          stockReservationQuantity: count,
          protectedData: {
            parentTransactionId: parentTransactionId,
          },
        },
      };

      const handleSuccess = response => {
        return response?.data?.data?.id?.uuid;
      };

      const handleError = error => {
        console.log('error¯¯¯¯¯s̄', error);
      };

      return initiatePrivileged({
        isSpeculative: false,

        bodyParams,
        queryParams,
      })
        .then(handleSuccess)
        .catch(handleError);
    });

    return Promise.all(chidTransaction).then(childIds => {
      const parentProtectedData = {
        childTransactions: childIds,
      };

      // Update child transaction ids
      const parentBodyParams = {
        id: parentTransactionId,
        transition: transitions.UPDATE_CHILD_TRANSACTIONS,
        params: {
          protectedData: parentProtectedData,
        },
      };

      return sdk.transactions
        .transition(parentBodyParams, {
          expand: true,
        })
        .then(res => {
          const entities = denormalisedResponseEntities(res);
          const order = entities[0];
          return order;
        });
    });
  } catch (error) {}
};

/**
 * update child transactions
 */
export const updateChildTransactions = ({ parentId, childIds, isBookingProcess }) => (
  dispatch,
  getState,
  sdk
) => {
  if (!isBookingProcess) {
    const parentBodyParams = {
      id: parentId,
      transition: transitions.CONFIRM_PAYMENT,
      params: {},
    };
    const parentResponse = sdk.transactions.transition(parentBodyParams, {
      expand: true,
    });
  }

  const childResponse = childIds.map(item => {
    const childBodyParams = {
      id: item,
      transition: transitions.CHILD_CONFIRM_PAYMENT,
      params: {},
    };
    const childResponse = sdk.transactions.transition(childBodyParams, {
      expand: true,
    });

    return {
      orderId: new UUID(parentId),
      messageSuccess: true,
      paymentMethodSaved: true,
    };
  });

  // return childIds.map(item => {
  //   const childBodyParams = {
  //     id: item?.childTransactionId,
  //     transition: transitions.CHILD_CONFIRM_PAYMENT,
  //     params: {},
  //   };
  //   return sdk.transactions.transition(childBodyParams, {
  //     expand: true,
  //   });
  // });
};

/**
 * initiate child bookings
 */
export const initiateChildBookings = ({
  orderParams,
  childTransitionName,
  parentTransitionName,
  processAlias,
}) => (dispatch, getState, sdk) => {
  try {
    const { attributes, id, relationships } = orderParams;
    const parentTransactionId = id.uuid;
    const { cart, deliveryMethod } = attributes?.protectedData;

    const firstKey = Object.keys(cart)[0];
    delete cart[firstKey];

    cart?.deliveryMethod ? delete cart?.deliveryMethod : null;

    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };
    const chidTransaction = Object.keys(cart).map(item => {
      const bodyParams = {
        processAlias: processAlias,
        transition: childTransitionName,
        params: {
          listingId: cart[item]?.staffId,
          bookingStart: new Date(Number(cart[item]?.bookingStart)),
          bookingEnd: new Date(Number(cart[item]?.bookingEnd)),
          protectedData: {
            serviceId: item,
            unitType: 'hour',
            deliveryMethod: 'pickup',
            parentTransactionId: parentTransactionId,
          },
        },
      };
      const handleSuccess = response => {
        return response?.data?.data?.id?.uuid;
      };

      const handleError = error => {
        console.log('error?????', JSON.stringify(error));
      };

      return initiatePrivileged({
        isSpeculative: false,
        bodyParams,
        queryParams,
      })
        .then(handleSuccess)
        .catch(handleError);
    });
    return Promise.all(chidTransaction).then(childIds => {
      // Update child transaction ids
      const parentBodyParams = {
        id: parentTransactionId,
        transition: parentTransitionName,
        params: {
          protectedData: {
            childTransactions: childIds,
          },
        },
      };

      return sdk.transactions
        .transition(parentBodyParams, {
          expand: true,
        })
        .then(res => {
          const entities = denormalisedResponseEntities(res);
          const order = entities[0];
          return order;
        });
    });
  } catch (error) {}
};

/**
 * update child bookings
 */
export const updateChildBookings = ({ orderParams, transitionName, childIds }) => (
  dispatch,
  getState,
  sdk
) => {
  try {
    return Promise.all(
      childIds.map(item => {
        const childBodyParams = {
          id: item,
          transition: transitionName,
          params: {},
        };
        return sdk.transactions.transition(childBodyParams, {
          expand: true,
        });
      })
    ).then(res => {
      return orderParams;
    });
  } catch (error) {}
};

// replace this thunk later in cart slice
export const clearAuthorCart = () => (dispatch, getState, sdk) => {
  try {
    const state = getState();
    const currentAuthor = state?.CartPage?.currentAuthor;
    const cart = state?.CartPage?.cart;

    const newCart = {
      ...cart,
    };

    delete newCart[currentAuthor?.id?.uuid];

    return sdk.currentUser.updateProfile({
      privateData: {
        cart: newCart,
      },
    });
  } catch (error) {}
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate transaction against default-inquiry process
 * Note: At this point inquiry transition is made directly against Marketplace API.
 *       So, client app's server is not involved here unlike with transitions including payments.
 *
 * @param {*} inquiryParams contains listingId and protectedData
 * @param {*} processAlias 'default-inquiry/release-1'
 * @param {*} transitionName 'transition/inquire-without-payment'
 * @returns
 */
export const initiateInquiryWithoutPayment = (inquiryParams, processAlias, transitionName) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(initiateInquiryRequest());

  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(initiateInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const bodyParams = {
    transition: transitionName,
    processAlias,
    params: inquiryParams,
  };
  const queryParams = {
    include: ['provider'],
    expand: true,
  };

  return sdk.transactions
    .initiate(bodyParams, queryParams)
    .then(response => {
      const transactionId = response.data.data.id;
      dispatch(initiateInquirySuccess());
      return transactionId;
    })
    .catch(e => {
      dispatch(initiateInquiryError(storableError(e)));
      throw e;
    });
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const { deliveryMethod, quantity, bookingDates, cart, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = deliveryMethod ? { cart, deliveryMethod } : { cart };

  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition: transitionName,
        params: transitionParams,
      }
    : {
        processAlias,
        transition: transitionName,
        params: transitionParams,
      };

  console.log('bodyParams...', JSON.stringify(bodyParams, null, 2));

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams?.listingId?.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

/**
 * Creates individual stock reservation transactions for all the listings in
 * the associated transaction's protectedData.cart, and updates the main
 * transaction protected data with the child transaction ids.
 * @param {*} tx Main cart transaction – expects protectedData.cart
 * @param {*} processAlias Cart stock process alias
 * @param {*} cartItemTransitionName Transition to initiate stock transaction
 * @param {*} parentTransitionName Transition to update the main transaction with child transaction ids
 * @returns 'order', i.e. the updated transaction
 */
export const createStockReservationTransactions = (
  tx,
  processAlias,
  cartItemTransitionName,
  parentTransitionName
) => (dispatch, getState, sdk) => {
  const listingsCart = { ...tx.attributes.protectedData.cart };
  delete listingsCart.deliveryMethod;
  // Create transactions with the cart item process to update stock reservations
  if (listingsCart.length == 0) {
    return tx;
  } else {
    const additionalTransactionFns = Object.keys(listingsCart).map(item => {
      const count = listingsCart[item].count;
      // initiate
      const bodyParams = {
        processAlias: processAlias,
        transition: cartItemTransitionName,
        params: {
          listingId: item,
          stockReservationQuantity: count,
          protectedData: {
            parentTransactionId: tx.id.uuid,
          },
        },
      };

      return sdk.transactions.initiate(bodyParams, { expand: true }).then(res => {
        return {
          listingId: item,
          stockTransactionId: res.data.data.id.uuid,
        };
      });
    });

    return Promise.all(additionalTransactionFns)
      .then(txs => {
        const childTransactions = txs.reduce((children, child) => {
          return {
            ...children,
            [child.listingId]: child.stockTransactionId,
          };
        }, {});

        const parentProtectedData = {
          ...tx.attributes.protectedData,
          childTransactions,
        };

        // Update child transaction ids
        const parentBodyParams = {
          id: tx.id,
          transition: parentTransitionName,
          params: {
            protectedData: parentProtectedData,
          },
        };

        return sdk.transactions.transition(parentBodyParams, { expand: true });
      })
      .then(resp => {
        return resp.data.data;
      });
  }
};

/**
 * Updates the specified transaction's child transactions using the specified transition
 * @param {*} tx Main transaction – expects protectedData.childTransactions
 * @param {*} transitionName Transition to apply to protectedData.childTransactions array
 * @returns 'tx', i.e. the main transaction
 */
export const updateStockReservationTransactions = (tx, transitionName) => (
  dispatch,
  getState,
  sdk
) => {
  const childTransactions = Object.values(tx.attributes.protectedData.childTransactions);

  const updateFns = childTransactions.map(childTx => {
    const bodyParams = {
      id: childTx,
      transition: transitionName,
      params: {},
    };

    return sdk.transactions.transition(bodyParams).then(resp => {
      return resp.data;
    });
  });

  Promise.all(updateFns);

  return tx;
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};
