import { onGetDealsCategoriesList } from '../../util/api';

/** Action Types */
const DEAL_CATEGORIES_REQUEST = 'app/DealsPage/DEAL_CATEGORIES_REQUEST';
const DEAL_CATEGORIES_SUCCESS = 'app/DealsPage/DEAL_CATEGORIES_SUCCESS';
const DEAL_CATEGORIES_ERROR = 'app/DealsPage/DEAL_CATEGORIES_ERROR';

/** Initial State */
const initialState = {
  loading: false,
  data: {
    dealCategories: [],
  },
  error: null,
};

/** Action Creators */
export const fetchDealCategoriesRequest = () => ({
  type: DEAL_CATEGORIES_REQUEST,
});

export const fetchDealCategoriesSuccess = data => ({
  type: DEAL_CATEGORIES_SUCCESS,
  payload: data,
});

export const fetchDealCategoriesError = error => ({
  type: DEAL_CATEGORIES_ERROR,
  payload: error,
});

/** Reducer */
const dealCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEAL_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DEAL_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          dealCategories: action.payload,
        },
      };
    case DEAL_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default dealCategoriesReducer;

/** Thunk Action Creator */
export const loadData = () => dispatch => {
  dispatch(fetchDealCategoriesRequest());

  // Using then and catch for promise handling
  return onGetDealsCategoriesList()
    .then(response => {
      dispatch(fetchDealCategoriesSuccess(response));
    })
    .catch(error => {
      dispatch(fetchDealCategoriesError(error.message || 'Failed to load data'));
    });
};
